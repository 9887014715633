<template>
	<div>
		<div class="bg bg3">
			<div class="mask"></div>
			<div class="text">
				<h1>项目案例</h1>
				<p>PROJECT CASE</p>
			</div>
		</div>
		<div class="case-main">
			<h1 style="margin-top: 40px;">项目背景</h1>
			<h4>BACKGROUND</h4>
			<h4 style="color: #6C88BF;">根据应急管理部门统计数据，截至10月31号，2021年全国已经发生电动车火灾</h4>

			<div class="flex flex-hc flex-vc case-top">
				<div class="left">
					<img src="../assets/img/case2.png" alt="智慧用电">
				</div>
				<div class="right" style="text-align: left;margin-left: 50px;">
					<p style="font-weight: bold;">消防部门数据统计：</p>
					<p><span>80%</span>的电动单车火灾发生在充电时段</p>
					<p><span>66.7%</span>的电动车火灾发生在20时~次日5时之间</p>
					<p>电动单车火灾致人伤亡的，<span>90%</span>是因为电动单车停放在门厅、过道等区域</p>
				</div>
			</div>

			<h4 style="margin-top: 50px;color: #6C88BF;">人口密集城区消防安全形势与基层管理面临的挑战</h4>
			<p>据国家能源局公布的数据，近年来我国的社会用电量持续上升，由此带来的安全风险持续增加</p>

			<div class="flex flex-hc flex-vc">
				<ul>
					<li>
						<div>
							<img src="../assets/img/case-icon1.png" width="40" alt="智慧用电">
						</div>
						<div>
							<h3>设备老化感知难</h3>
							<p>电器线路老化或者配置不合理，容易引发火灾</p>
						</div>
					</li>
					<li>
						<div><img src="../assets/img/case-icon2.png" width="40" alt="智慧用电"></div>
						<div>
							<h3>违规用电难治理</h3>
							<p>违规治理存在取证难，执法难，屡禁不止</p>
						</div>
					</li>
					<li>
						<div><img src="../assets/img/case-icon3.png" width="40" alt="智慧用电"></div>
						<div>
							<h3>精细化管理难覆盖</h3>
							<p>用电场所数量多，位置分散，距离远、设备信号多、基层管理专业人员少、缺乏平台化管理工具</p>
						</div>
					</li>
					<li>
						<div><img src="../assets/img/case-icon4.png" width="40" alt="智慧用电"></div>
						<div>
							<h3>事后补救难预防</h3>
							<p>从事后处理转向事前防御，杜绝安全隐患引起的事故发生是用电安全管理工作的提升目标</p>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<div class="solutions-main">
			<h1 style="margin-top: 40px;">解决方案</h1>
			<h4>SOLUTIONS</h4>
			<h4 style="color: #6C88BF;">堵疏一体解决方案</h4>
			<div class="flex flex-hc flex-vc" style="color: #0F13AE;">
				<div>
					<img src="../assets/img/case1.png" width="200" alt="智慧用电">
				</div>
				<div style="margin: 0 50px;font-size: 24px;">
					》》
				</div>
				<div
					style="width: 150px;height: 150px;border-radius: 50%;line-height: 150px;border: 2px solid #0F13AE;">
					<img src="../assets/img/24gl-building.png" width="100" style="vertical-align: middle;" alt="">
				</div>
				<div style="margin: 0 50px;font-size: 24px;">《《</div>
				<div>
					<img src="../assets/img/case3.png" width="200" alt="智慧用电">
				</div>
			</div>
			<div class="flex flex-hc flex-vc">
				<div class="solu-box">
					<h3>堵：加强管理</h3>
					<p style="line-height: 40px;">通过智能用电终端对末端电网的运行状况实时监测，一旦系统发现违规充电事件，立即告警并通知社区管理员，由社区网格员上门制止或远程断电</p>
				</div>
				<div class="solu-box" style="margin: 0 70px;">
					<h3>绿色社区</h3>
					<p>与多部门配合加强管控，预防火灾发生，共建宜居绿色社区</p>
					<div style="border: 1px #727271 dashed;"></div>
					<h3>双碳目标</h3>
					<p>远期为实现国家双碳目标提供基层数据支撑</p>
				</div>
				<div class="solu-box">
					<h3>疏：满足需求</h3>
					<p style="line-height: 40px;">因地制宜地安装电动单车智能充电桩和充电站，给社区居民一个方便、安全、离家近的电动自行车充电环境，满足人民群众充电需求</p>
				</div>
			</div>
		</div>

		<div class="demosbase-main">
			<h1 style="margin-top: 40px;">示范基地</h1>
			<h4>DEMONSTRATION BASE</h4>
			<div class="flex flex-hc flex-vc" style="margin-top: 50px;">
				<div>
					<img src="../assets/img/demos-base1.png" alt="智慧监控大屏" width="600" />
				</div>
				<p style="text-indent:32px;line-height: 40px;text-align: justify;font-weight: bold;margin:0 0 0 50px;">
					深圳市南山区某街道办系统平台与微品能源智慧社区平台进行数据打通对接，携手为居民打造用电安全智慧网络。利用“技术+宣传+管理”的手段，构建起柔性治理的网格，运用制度约束和方式创新，创造新形势下社会民生安全治理的新模式，为社区提供全生命周期的用电监控，以及实时监控各区域用电动态与危险预警，实现用电安全的精细化管理和高效运管，协助区内各社区、街道优化能源管控。
				</p>
			</div>
			<div class="flex flex-hc" style="margin: 50px 0;">
				<p style="text-indent:32px;line-height: 40px;text-align: justify;font-weight: bold;margin:0;">
					本项目自实施以来，得到了市、区、街道各级领导的重视，并亲临实地考察指导，给予高度认可；----该方案可以解决城市数量庞大电动自行车在室内违规充电的问题，也能解决老旧小区过载、电线老化、漏电等安全问题；还能为所有家庭用户建立碳排放账户，为双碳目标达成做贡献！
				</p>
				<!-- <div style="margin-left: 50px;">
					<div class="flex">
					<img src="../assets/img/demos-base2.png" width="400" alt="">
					<img src="../assets/img/demos-base3.png" width="400" style="margin-left: 50px;" alt="">
				</div>
				<p>市委领导现场考察</p>
				</div> -->
			</div>
			<!-- <div style="margin-top: 50px;">
			<div class="flex flex-sb">
				<img src="../assets/img/demos-base4.png" width="355" alt="">
				<img src="../assets/img/demos-base5.png" width="400" alt="">
				<img src="../assets/img/demos-base6.png" width="400" alt="">
			</div>
			<p>区委区政府领导现场考察</p>
			</div> -->
			 


		</div>
		<foot></foot>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '微品能源-项目案例',
			meta: [{
					name: 'keywords',
					content: '微品能源,项目案例,智慧用电'
				},
				{
					name: 'description',
					content: '微品能源官方网站'
				}
			]
		}
	}
</script>

<style scoped>
	.demosbase-main {
		width: 1200px;
		margin: 0 auto;
	}

	.solu-box {

		width: 200px;
		background-color: #FFFFFF;
		padding: 0 20px;
		height: 320px;
		border-radius: 4px;

	}

	.solu-box p {
		text-align: justify;
		line-height: 30px;
	}

	.solutions-main {
		background-color: #f7f8f9;
		padding: 10px 0 50px 0;
	}

	.case-main {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 30px;

	}

	.case-top {
		margin-top: 50px;
	}

	.case-top .left {}

	.case-top .left img {
		width: 201px;
		height: 201px;
		border-radius: 50%;


	}




	.case-main p span {
		color: #E4364F;
		font-size: 20px;
	}

	.case-main ul li div {
		display: inline-block;
		vertical-align: middle;
	}

	.case-main ul li div:nth-of-type(1) {
		background-color: #2B52A2;
		border-radius: 50%;
		width: 80px;
		height: 80px;
		position: relative;
		margin-right: 30px;
	}



	.case-main ul li div img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		vertical-align: middle;
	}

	.case-main ul li {
		text-align: left;
		list-style-type: none;
		margin-top: 30px;

	}

	.case-main ul li h3 {
		margin: 0;
		color: #E4364F;
	}

	.case-main ul li p {
		margin-top: 10px;
	}

	.bg {
		background-size: cover;
		width: 100%;
		height: 500px;
		position: relative;

	}

	.bg .mask {
		background: rgba(0, 0, 0, 0.4);
		position: absolute;
		width: 500px;
		height: 400px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.bg .text {
		font-size: 40px;
		color: #ffffff;
		font-weight: bold;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.bg3 {
		background-image: url('../assets/img/anli-banner.jpg');
	}
</style>
